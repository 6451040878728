import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import UIkit from 'uikit';
import APP_CONST from '@/constants/AppConst';
import DatepickerComponent from '@/ui-components/datepicker/DatepickerComponent.vue';
import programList from '@/store/modules/programList';



@Component({
  components: {
    'datepicker': DatepickerComponent,
  }
})
export class SendInvitationUser extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    public scheduleDate:string = APP_CONST.BLANK;

    @Prop({default: ''})
    component!: string;

    @Prop()
    sendInvitationUserID!:[];

    @Prop()
    popupFunction!:string;
    
    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    closePopup(){
      if(UIkit.modal('#send-invitation-popup')){   
        UIkit.modal('#send-invitation-popup').hide();
      }
      this.scheduleDate = APP_CONST.BLANK;
    }

    sendInvitationUsers(){
      if(this.popupFunction=='revokeAccess'){
        programList.mutateScheduleDate(this.scheduleDate);
        this.$emit('revokeAccess', this.scheduleDate);
        setTimeout(() => {
          this.scheduleDate = APP_CONST.BLANK;
        }, APP_CONST.FIFTY);
      }
      else{
        programList.mutateScheduleDate(this.scheduleDate);
        this.$emit('sendInvitation', this.scheduleDate);
        setTimeout(() => {
          this.scheduleDate = APP_CONST.BLANK;
        }, APP_CONST.FIFTY);
      }
    }
}